import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

const bgImage = "/header.jpg"

export default () => (
	<Layout>
		<SEO pageTitle="Face treatments" />
		<Header pageTitle="Face treatments" bgImage={bgImage} />
		<section className="intro-text">
			<div className="container">
				<p>Face treatments intro.</p>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>Express prescription facial &ndash; &pound;32</h2>
				<p>Here you have the option of either having a professional double cleanse, tone, gentle enzyme exfoliation, hydrating and balancing masks, finished with a hydrating serum and moisturiser. &nbsp;Or, a professional double cleanse &amp; tone, followed by a relaxing facial massage, to tone and ease away your daily stresses. &nbsp;All skin types will benefit from a regular facial. &nbsp;Time taken 30 minutes.</p>
				<Link className="button cta" aria-label="Book Express prescription facial now" to="/book">Book now</Link>
			</div>
		</section>
		<section>
			<div className="container">
				<h2>Full prescription facial &ndash; &pound;48</h2>
				<p>Having difficulty choosing between the 2 different express facials, then why not combine the 2 and fully experience the benefits of both a bespoke treatment plan together with a toning and de-stressing facial massage. &nbsp;Time taken 1 hour.</p>
				<Link className="button cta" aria-label="Book Full prescription facial now" to="/book">Book now</Link>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>Facial waxing &ndash; from &pound;5</h2>
				<p>Hard wax is used to remove unwanted facial hair in a safe and efficient way. &nbsp;Leaving the area smooth for weeks. &nbsp;Time taken depends on the number of areas being treated but can start from as little as 5 minutes. &nbsp;This treatment is a great add on to other treatments.</p>
				<Link className="button cta" aria-label="Book Facial waxing now" to="/book">Book now</Link>
			</div>
		</section>
	</Layout>
)